import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router'
import Index from '@/views/Index.vue'
import Boarding from '@/views/Boarding.vue'
import Help from '@/views/Help.vue'
import InnerHelp from '@/views/Help.vue'
import WorkOrder from '@/views/WorkOrder.vue'
import Intro from '@/views/Intro.vue'
import Home from '@/views/Home.vue'
import ImageMarket from '@/views/ImageMarket.vue'
import InstanceList from '@/views/InstanceList.vue'
import InstanceRegionMigrateLog from '@/views/InstanceRegionMigrateLog.vue'
import InstanceVolumeList from '@/views/InstanceVolumeList.vue'
import InstanceCreate from '@/views/InstanceCreate.vue'
import Dashboard from '@/views/Dashboard.vue'
import ProductList from '@/views/ProductList'
import ProductDetails from '@/views/ProductDetails'
import OperationHistory from '@/views/OperationHistory.vue'
import Me from '@/views/Me.vue'
import StatusCheck from '@/views/StatusCheck.vue'
import LoginByToken from '@/views/LoginByToken.vue'
import InstanceDetails from '@/views/InstanceDetails.vue'

import store from '../store/index'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Index',
        component: Index,
        redirect: '/intro',
        children: [
            {
                path: '/intro',
                name: 'Intro',
                component: Intro
            },
            {
                path: '/check',
                name: 'StatusCheck',
                component: StatusCheck
            },
            {
                path: '/help',
                name: 'Help',
                component: Help
            },
            {
                path: '/boarding',
                name: 'Boarding',
                component: Boarding
            },
            {
                path: '/loginByToken/:token',
                name: 'LoginByToken',
                component: LoginByToken
            },
        ]
    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
        redirect: '/home/dashboard',
        children: [
            {
                path: '/home/dashboard',
                name: 'Dashboard',
                component: Dashboard
            },
            {
                path: '/home/productList',
                name: 'ProductList',
                component: ProductList
            },
            {
                path: '/home/productDetails/:productId',
                name: 'ProductDetails',
                component: ProductDetails
            },
            {
                path: '/home/imageMarket',
                name: 'ImageMarket',
                component: ImageMarket
            },
            {
                path: '/home/help',
                name: 'HelpInner',
                component: InnerHelp
            },
            {
                path: '/home/workOrder',
                name: 'WorkOrder',
                component: WorkOrder
            },
            {
                path: '/home/instanceList',
                name: 'InstanceList',
                component: InstanceList
            },
            {
                path: '/home/instanceRegionMigrateLog',
                name: 'InstanceRegionMigrateLog',
                component: InstanceRegionMigrateLog
            },
            {
                path: '/home/instanceDetails/:instanceId',
                name: 'InstanceDetails',
                component: InstanceDetails
            },
            {
                path: '/home/instanceVolumeList',
                name: 'InstanceVolumeList',
                component: InstanceVolumeList
            },
            {
                path: '/home/instanceCreate/:region/:imageScope/:imageId',
                name: 'InstanceCreate',
                component: InstanceCreate
            },
            {
                path: '/home/operationHistory',
                name: 'OperationHistory',
                component: OperationHistory
            },
            {
                path: '/home/me',
                name: 'Me',
                component: Me
            }
        ]
    },
    {
        path: '/notfound',
        component: () => import('../views/NotFound')
    },
    {
        path: '/:pathMatch(.*)',
        redirect: '/notfound'
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

const anonymousPages = new Set(["Boarding", "Help", "Intro", "StatusCheck", "LoginByToken"])

// 全局路由守卫
router.beforeEach((to, from, next) => {
    if (anonymousPages.has(<string>to.name)) {
        next();
    }
    const token = store.getters['token'];
    if (!token) {
        // 跳转到登录页面
        next({name: "Boarding"});
        return;
    }
    const expiredAt = store.getters['expiredAt'];
    if (Number(Date.now()) > Number(expiredAt)) {
        console.log("token已过期，请重新登录");
        next({name: "Boarding"});
        return;
    }
    next();
});

export default router
