<template>
  <div id="container">
    <h1>您当前的IP为：<span style="color: #409EFF">{{ currentIp }}</span></h1>
    <h4 style="color:grey;margin-top: 8px">
      如出现多次SSH、Jupyter、Rstudio软件登录失败等行为，系统会自动对异常IP封禁，可以联系管理员解锁
    </h4>
    <h4 style="color:grey;margin-top: 8px">
      当前已封锁
      <el-tag type="danger">{{ banIpSize }}</el-tag>
      个异常IP，西柚云持续守卫您的实例
    </h4>
    <h4 style="color:grey;margin-top: 8px" v-if="whiteClientIp">
      当前IP <span style="color: #409EFF">{{ whiteClientIp.whiteIp }}</span> 已添加到白名单，原因为 <span
        style="color: #409EFF">{{ whiteClientIp.whiteReason }}</span>，过期时间为
      <span style="color: #409EFF">{{ whiteClientIp.expireTime || "不过期" }}</span>
    </h4>
    <div id="banClientIpListTable">
      <el-table :data="banClientIpList" style="width: 100%">
        <el-table-column label="封禁机房" prop="regionStr"/>
        <el-table-column label="封禁IP" prop="banIp"/>
        <el-table-column label="封禁时间" prop="banTime"/>
        <el-table-column label="封禁原因" prop="banReason"/>
        <el-table-column align="right">
          <template #header>
            <div style="display: flex; align-items: center;justify-content: space-between">
              <el-input v-model="searchIp" size="small" placeholder="输入IP查询是否被封禁"
                        @keyup.enter="getBanClientIpList"/>
              <el-button size="small" type="primary" @click="getBanClientIpList" style="margin-left: 4px">查询
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {
  serverDomain,
} from '@/common/constant'
import {defineComponent} from 'vue';
import {useStore} from 'vuex'

export default defineComponent({
  name: 'BanClientIp',
  data() {
    return {
      currentIp: '',
      searchIp: null,
      banIpSize: 0,
      banClientIpList: [],
      whiteClientIp: null
    }
  },
  mounted() {
    this.getCurrentIp();
    this.getBanClientIpListSize();
  },
  methods: {
    getCurrentIp() {
      this.$httpUtil.get('/dayu/api/v1/anonymous/get-current-ip', {}).then(res => {
        if (res) {
          this.currentIp = res.data;
          this.searchIp = this.currentIp;
          this.getBanClientIpList();
        }
      }, (res) => {
        console.log(res);
      });
    },
    getBanClientIpList() {
      this.$httpUtil.get('/dayu/api/v1/anonymous/get-client-security-data', {
        searchIp: this.searchIp
      }).then(res => {
        if (res) {
          this.banClientIpList = res.data.banClientIpList;
          this.whiteClientIp = res.data.whiteClientIp;
        }
      }, (res) => {
        console.log(res);
      });
    },
    getBanClientIpListSize() {
      this.$httpUtil.get('/dayu/api/v1/anonymous/get-ban-client-ip-size', {}).then(res => {
        if (res) {
          this.banIpSize = res.data;
        }
      }, (res) => {
        console.log(res);
      });
    },
  }
});
</script>

<style scoped lang="less">
@import "@/assets/style/common.less";

#container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .card-item();

  #banClientIpListTable {
    width: 100%;
  }
}

</style>
